import { DbTable } from "@/helpers/DbTable";
import Helpers from "@/helpers/ipc-helpers";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImport } from "../base-import";
import { ImportResponse } from "../import-response";
import { LinkWalletsResponse } from "../link-wallets-response";
import NimiqApi, { NimiqTransaction } from "./nimiq-api";

export const linkWalletHashesFrontend = (transactions: Transaction[]): Promise<LinkWalletsResponse>  => {
  return new Promise((resolve, reject) => {
    const walletResponse: LinkWalletsResponse = {
      new: 0,
      linked: 0
    }
    // for each hash key

    // console.debug('txs in link hashes...', transactions.map(t => t.source))
    const hashes = transactions.map(t => t.hash).filter(hash => !!hash);
    // console.debug('hashess...', hashes, hashesNo0x, hashes0x);
    Helpers.dbFind(DbTable.TRANSACTIONS, { 'hash': { $in: hashes } })
      .then((docs: Transaction[]) => {
        const matchingHashes: string[] = docs.map(d => d.hash);
        const otherTransactions: Transaction[] = transactions.filter(tx => {
          return matchingHashes.indexOf(tx.hash) === -1;
        }).map(t => TransactionFactory.fromDB(t));

        walletResponse.new = otherTransactions.length;
        Promise.all(docs.map(d => {
          return new Promise((resolve, reject) => {
            const children = transactions.filter(tx => {
                return tx.hash && d.hash && tx.hash === d.hash;
              });
            walletResponse.linked += children.length;
            Helpers.addChildren({ id: d._id, children: children.map(t => TransactionFactory.toDB(t)) })
              .then((addChildrenRes) => { resolve(addChildrenRes); })
              .catch((addChildrenErr) => { reject(addChildrenErr); });
          });
        })).then(linkAllDocsRes => {
            // console.debug('other transactions', linkAllDocsRes, otherTransactions.length);
            Helpers.dbInsert(DbTable.TRANSACTIONS, otherTransactions.map(t => TransactionFactory.toDB(t)))
              .then(newDocs => {
                // console.debug('compare newDocs to estimated...', newDocs.length, walletResponse.new);
                resolve(walletResponse);
              })
              .catch(err => reject(err))
          })
          .catch(allDocsErr => reject(allDocsErr));
      })
      .catch(err => reject(err))
  });
}

export default class NimiqImport implements BaseImport {
  declare api: NimiqApi;
  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials, address?: string, startblock = 0) {
    this.api = new NimiqApi(creds, address);
  }

  

  import(): Promise<ImportResponse> {
    const importResponse: ImportResponse = {
      existing: 0,
      linked: 0,
      new: 0
    }
    return new Promise((resolve, reject) => {
      Helpers.dbFind(DbTable.TRANSACTIONS, { 'source.myAddress': (this.api as NimiqApi).address, 'source.name': 'Nimiq Transaction' }, { 'source.blockHeight': 1 }, null, { date: -1 })
        .then((docs: Transaction[]) => {
          importResponse.existing += docs.length;
          const block = docs[0] ? Number(docs[0].source.blockHeight) + 1 : 0;
          this.api.fetchTransactions(block)
          .then((txs: NimiqTransaction[]) => {
            linkWalletHashesFrontend(txs)
              .then((walletResponse: LinkWalletsResponse) => {
                importResponse.new += walletResponse.new;
                importResponse.linked += walletResponse.linked;
                resolve(importResponse);
              })
              .catch(err => reject(err))
          })
          .catch(err => reject(err))
        })
        .catch(err => reject(err));
    });
  }
}