




























































































































































































































import HubApi, { SignedTransaction } from '@nimiq/hub-api';
import env from '@/services/env';
import Jumbo from '../components/Jumbo.vue';
import Downloads from '../components/Downloads.vue';
import axios from 'axios';
import NimiqValidation from '@/services/nimiq/nimiq-validation';

export default {
  components: {
    Jumbo,
    Downloads
  },
  data() {
    return {
      purchasedTx: null,
      showCode: false,
      nimiqPrice: 0.001
    };
  },
  computed: {
    nimiqHubUrl: () => env.NIMIQ_HUB_URL,
    nimiqRecipient: () => env.NIMIQ_RECIPIENT,
    symbolTaxUrl: () => env.SYMBOL_TAX_URL
  },
  created() {
    // for embedding gumroad
    const gumroadScript = document.createElement('script');
    gumroadScript.setAttribute('src', 'https://gumroad.com/js/gumroad.js');
    document.head.appendChild(gumroadScript);
  },
  methods: {
    trackOutbound(location) {
      if (window.gtag) {
        window.gtag('event', 'conversion_event_outbound_click', {
          download_location: location
        });
      }
    },
    buyWithNimiq() {
      axios.get('https://api.coinstats.app/public/v1/coins/nimiq-2?currency=USD')
        .then((res) => {
          this.nimiqPrice = res.data.coin.price;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          const hub = new HubApi(this.nimiqHubUrl)
          const value = Math.ceil(1e5 * 69 / this.nimiqPrice) // price * amount NIM = cost (69), amount Luna = (cost / price) * 1e5
          hub.checkout({
            appName: `Symbol Tax`,
            value,
            recipient: this.nimiqRecipient,
            shopLogoUrl: `${this.symbolTaxUrl}/icon.png`,
            extraData: `Symbol Tax License https://symbol.tax ~$69 ($${this.nimiqPrice.toFixed(4)}/NIM)`
          })
            .then((tx: SignedTransaction) => {
              // console.debug(tx);
              const validation: NimiqValidation = {
                value: tx.raw.value,
                sender: tx.raw.sender,
                recipient: tx.raw.recipient,
                hash: tx.hash,
                createdAt: new Date()
              };
              this.purchasedTx = btoa(JSON.stringify(validation));
              this.$nextTick(() => {
                this.$refs['openNimiq'].click();
              })
            })
            .catch(err => {
              console.error(err);
            })
        })
      this.trackOutbound('nimiq')
    }
  }
};
