import { ApiCredentials, BaseApi, BaseApiService, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";
import NimiqValidation, { NimiqSignedMessage } from "./nimiq-validation";
import { Transaction } from "@/models/transaction";
import Decimal from "decimal.js-light";
import md5 from "blueimp-md5";
// import { spawn, Thread, Worker } from "threads";
// import { NimiqWorker } from "@/workers/nimiq-worker";
import nimiqWorker, { NimiqWorker } from "@/workers/nimiq-worker";
import HubApi from "@nimiq/hub-api";
// import store from "@/store";

// console.debug('__nimiq in main thread..', global.__nimiq);

// console.debug('isNodeJs', PlatformUtils.isNodeJs());
// (process as any).dlopen = () => {
//   throw new Error('Load native module is not safe')
// }

// async function spawnWorker(): Promise<NimiqWorker> {
//   return await spawn<NimiqWorker>(new Worker('@nimiq-build/src/worker', { type: 'module' }));
// }

export interface NimiqApiTransaction {
  timestamp: number;
  block_height: number;
  hash: string;
  sender_address: string;
  receiver_address: string;
  value: number;
  fee: number;
  data: string;
  confirmations: number;
}

export interface NimiqApiBlock {
  fees: number; 
  hash: string;
  height: number;
  reward: number;
  timestamp: number;
}

export default class NimiqApi extends BaseApiService implements BaseApi {
  public static API_URL = env.NIMIQ_API_URL;
  static RECIPIENT = env.NIMIQ_RECIPIENT;
  static worker: NimiqWorker;
  address: string;

  private get validation(): NimiqValidation | NimiqSignedMessage {
    try {
      const result = JSON.parse(Buffer.from(this._credentials.key, 'base64').toString());
      if (result.sender) {
        return (result as NimiqValidation);
      } else {
        return {
          signer: result.signer,
          signature: new Uint8Array(Object.values(result.signature)),
          signerPublicKey: new Uint8Array(Object.values(result.signerPublicKey))
        };
      }
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  }

  private get signedMessage(): NimiqSignedMessage {
    return this.validation as NimiqSignedMessage;
  }

  private get validationAsNimiq(): NimiqValidation {
    return this.validation as NimiqValidation;
  }

  private get hash(): string {
    return this.validationAsNimiq.hash || this._credentials.secret;
  }

  private get hashToB64(): string {
    return Buffer.from(this.hash, 'hex').toString('base64');
  }

  private get nimiqSignature(): Nimiq.Signature {
    return new Nimiq.Signature(this.signedMessage.signature);
  }

  private get nimiqPublicKey(): Nimiq.PublicKey {
    return new Nimiq.PublicKey(this.signedMessage.signerPublicKey);
  }

  private get message(): string {
    return decodeURIComponent(this._credentials.passphrase);
  }

  private get isValid(): boolean {
    const data = HubApi.MSG_PREFIX
      + this.message.length
      + this.message;
    const dataBytes = Nimiq.BufferUtils.fromUtf8(data);
    const hash = Nimiq.Hash.computeSha256(dataBytes);
    return this.nimiqSignature.verify(this.nimiqPublicKey, hash);
  }

  constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials, address?: string) {
    super(credentials);
    this.address = address;
  }

  private static async getWorker(): Promise<NimiqWorker> {
    if (!this.worker) {
      this.worker = nimiqWorker;
      await this.worker.start((window as Window).Nimiq);
    }
    return this.worker;
  }

  // private static async pushNewWorker() {
  //   try {
  //     const worker = await spawnWorker();
  //     this.workers.push(worker);
  //   } catch (e) {
  //     console.error(e);
  //     console.error(e.stack);
  //     throw new Error(e.message);
  //   }
  // }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      NimiqApi.getWorker().then(worker => {
        worker.getTransaction(this.hash)
          .then((clientTx: Nimiq.ClientTransactionDetails) => {
            const txValidation: NimiqValidation = {
              sender: clientTx.sender.toUserFriendlyAddress(),
              recipient: clientTx.recipient.toUserFriendlyAddress(),
              hash: clientTx.transactionHash.toHex(),
              createdAt: new Date(clientTx.timestamp * 1000),
              value: clientTx.value
            }

            // console.debug(this.signedMessage, this.hash, this.message , this.isValid);

            if (clientTx.confirmations < 2)
              reject('Transaction not confirmed... wait a few minutes and try again');
            if (txValidation.sender === this.validationAsNimiq.sender && txValidation.recipient === this.validationAsNimiq.recipient
              && txValidation.recipient === NimiqApi.RECIPIENT && txValidation.value === this.validationAsNimiq.value)
              resolve(true);
            else if (txValidation.sender === this.signedMessage.signer && txValidation.recipient === NimiqApi.RECIPIENT && this.isValid)
              //console.debug('Message signature verified!')
              resolve(true);
            else
              reject('Invalid key... If you are having trouble verifying your Nimiq purchase, email support@symbol.tax');
          })
          .catch(err => { console.error(err); reject('Could not validate Nimiq transaction... If you are having trouble verifying your Nimiq purchase, email support@symbol.tax'); });
      })
    })
  }

  async fetchTransactions(height = 0): Promise<NimiqTransaction[]> {
    const txs: NimiqApiTransaction[] = [];
    const accttxs = await this.getAccountTransactions(height);
    const acctblocks = await this.getAccountBlocks(height);
    return accttxs.map(t => NimiqTransaction.FromTx(t, this.address)).concat(acctblocks.map(b => NimiqTransaction.FromBlock(b, this.address)));
  }

  private getAccountTransactions(height = 0): Promise<Nimiq.ClientTransactionDetails[]> {
    return new Promise((resolve, reject) => {
      NimiqApi.getWorker()
        .then(worker => {
          worker.getTransactions(this.address, height)
            .then((txs: Nimiq.ClientTransactionDetails[]) => resolve(txs))
            .catch(err => reject(err));
        })
    })
  }

  private getAccountBlocks(height = 0): Promise<any[]> {
    return new Promise((resolve, reject) => {
      resolve([]);
      // NimiqApi.getWorker()
      //   .then(worker => {
      //     worker.getTransactions(this.address, height)
      //       .then((txs: Nimiq.ClientTransactionDetails[]) => resolve(txs))
      //       .catch(err => reject(err));
      //   })
    })
  }

  // private static async killWorker(worker: NimiqWorker) {
  //   try {
  //     await Thread.terminate(worker as any);
  //     console.debug('worker killed...');                           
  //   } catch(e) {
  //     console.error(e);
  //   }
  //   await this.pushNewWorker();
  // }
}

export class NimiqTransaction implements Transaction {
  _tx: Nimiq.ClientTransactionDetails;
  _block: NimiqApiBlock;
  _myAddress: string;
  get date(): Date { return new Date(this._tx ? this._tx.timestamp * 1000 : this._block.timestamp); }
  get amount(): Decimal { return new Decimal((this._tx ? this._tx.value : this._block.reward + this._block.fees) / 1e5); }
  get receivedSymbol(): string { return this._block || this._tx.recipient.toUserFriendlyAddress() === this._myAddress ? 'NIM' : null; }
  get receivedAmount(): Decimal { return this._block || this._tx.recipient.toUserFriendlyAddress() === this._myAddress ? this.amount : new Decimal(0); }
  get receivedCoinId(): string { return 'nimiq-2'; }
  get sentSymbol(): string { return this._tx && this._tx.sender.toUserFriendlyAddress() === this._myAddress ? 'NIM' : null; }
  get sentAmount(): Decimal { return this._tx && this._tx.sender.toUserFriendlyAddress() === this._myAddress ? this.amount : new Decimal(0); }
  get sentCoinId(): string { return 'nimiq-2'; }
  get price(): Decimal { return new Decimal(0); }
  get fee(): Decimal { return new Decimal(this._tx ? (this._tx.fee / 1e5) : new Decimal(0)); }
  get feeSymbol(): string { return 'NIM'; }
  get feeCoinId(): string { return 'nimiq-2'; }
  get description(): string { return null; }
  get source(): any {
    const details = this._tx ? { ...this._tx } : { ...this._block };
    return {
      name: `Nimiq ${this._tx ? 'Transaction' : 'Mined Block'}`,
      myAddress: this._myAddress,
      sender: this._tx ? this._tx.sender.toUserFriendlyAddress() : null,
      recipient: this._tx ? this._tx.recipient.toUserFriendlyAddress() : this._myAddress,
      hash: this._tx ? this._tx.transactionHash.toHex() : this._block.hash,
      blockHeight: this._tx ? this._tx.blockHeight : this._block.height,
      value: this._tx ? this._tx.value : this._block.reward,
      fee: this._tx ? this._tx.fee : this._block.fees
    };
  }
  get _id(): string { return md5(JSON.stringify(this.source)); }
  get children(): Transaction[] { return null; }
  get hash(): string { return this._tx ? this._tx.transactionHash.toHex() : this._block.hash; }

  constructor(myAddress: string, tx?: Nimiq.ClientTransactionDetails, block?: NimiqApiBlock) {
    this._tx = tx;
    this._block = block;
    this._myAddress = myAddress;
  }

  static FromTx(tx: Nimiq.ClientTransactionDetails, myAddress: string): NimiqTransaction {
    return new NimiqTransaction(myAddress, tx);
  }

  static FromBlock(block: NimiqApiBlock, myAddress: string): NimiqTransaction {
    return new NimiqTransaction(myAddress, null, block);
  }
}
