<template>
  <b-container>
    <router-view/>
    <the-footer></the-footer>
  </b-container>
</template>

<style lang="scss">
@import "@/assets/scss/custom";
</style>

<script>
import TheFooter from './components/TheFooter.vue'
export default {
  components: { TheFooter }
}
</script>
