<template>
  <b-container fluid id="docs">
    <div class="d-flex justify-content-between align-items-center mt-3">
      <h3>Documentation</h3>
      <router-link to="/"><img src="/icon.png" width="25" height="25" alt="Symbol Tax"></router-link>
    </div>    <hr>
    <b-row>
      <b-col md="3" class="border-right">
        <b-nav vertical class="sticky-top">
          <b-nav-item :to="route.path" v-for="route in docLinks" :key="route.name">{{ route.name }}</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="9">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    docLinks () {
      return this.$router.options.routes.find(r => r.name === 'Docs').children
    }
  }
}
</script>