import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import './ipc-invoker'
import { NODE_ENV } from '../environment/env.json'

declare global {
  interface Window {
    app: any;
    ipcRenderer: any;
    $vm: any;
    gtag: any;
  }
}
window.app = window.app || {}

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

const vm = new Vue({
  router,
  render: h => h(App)
});
vm.$mount('#app');
if (NODE_ENV === 'development' || NODE_ENV === 'test') {
  (window as Window).$vm = vm;
}
