import { ImportResponse } from "@/services/import-response";
import { ApiCredentials, TokenCredentials } from '@/services/base-api';
import { Filters, TransactionFilters } from '@/store/interfaces/filters';
import { BasePagination, PaginationFields } from '@/store/interfaces/pagination-fields';
import { GainLossEntry, PriceData } from '../models/gain-loss-entry';
import NimiqApi from "@/services/nimiq/nimiq-api";
import NimiqImport from "@/services/nimiq/nimiq-import";
import { DbTable } from "./DbTable";
import { CalcMethod } from "@/store/interfaces/CalcMethod";
import { CoinstatsDbCoin } from "@/services/coinstats/CoinstatsDbCoin";
import { DarkMode } from "@/ipc/DarkMode";
import FirebaseService from "@/services/firebase/firebase-service";
import { STAX_APP_ENV } from "@/environment/env.json"

export default class Helpers {
  static linkTheSuggestions() {
    return window.ipcRenderer.invoke('db:link:suggestions'); 
  }

  static dbFind(table: DbTable, payload: any = {}, options?: any, pagination?: BasePagination|PaginationFields, sorting?: any, filters?: Filters|TransactionFilters): Promise<any> {
    return window.ipcRenderer.invoke('db:find', table, payload, options, pagination, sorting, filters);
  }

  static dbFindById(table: DbTable, id: string): Promise<any> {
    return window.ipcRenderer.invoke('db:find:id', table, id); 
  }

  static dbInsert(table: DbTable, payload?: any): Promise<any> {
    return window.ipcRenderer.invoke('db:insert', table, payload);
  }

  static dbUpdate(table: DbTable, query: any, update: any, options?: any): Promise<any> {
    console.debug('db:update...', update);
    return window.ipcRenderer.invoke('db:update', table, query, update, options);
  }

  static dbCount(table: DbTable, payload?: any, filters?: Filters): Promise<any> {
    return window.ipcRenderer.invoke('db:count', table, payload, filters);
  }

  static dbRemove(table: DbTable, payload?: any, options?: any, filters?: Filters): Promise<any> {
    return window.ipcRenderer.invoke('db:remove', table, payload, options, filters);
  }

  static glCalc(payload: any[]): Promise<GainLossEntry[]> {
    // console.debug('glCalc called');
    return window.ipcRenderer.invoke('gl:calc', payload);
  }

  static glInit(prices: PriceData[], coins: CoinstatsDbCoin[], calcMethod: CalcMethod): Promise<boolean> {
    // console.debug('glInit called');
    return window.ipcRenderer.invoke('gl:init', prices, coins, calcMethod);
  }

  static glCalcTotal(payload: any): Promise<any> {
    // console.debug('glCalcTotal', payload);
    // return Promise.resolve({});
    return window.ipcRenderer.invoke('gl:calc:total', payload);
  }

  static glExport(year: string, payload: any, filters: Filters): Promise<any> {
    return window.ipcRenderer.invoke('gl:export', year, payload, filters);
  }

  static incomeExport(year: string, payload: any, filters: Filters): Promise<any> {
    return window.ipcRenderer.invoke('income:export', year, payload, filters);
  }

  static incomeCalc(payload: any, filters: TransactionFilters, portfolioCoins: any, prices: PriceData[]): Promise<any> {
    return window.ipcRenderer.invoke('income:calc', payload, filters, portfolioCoins, prices);
  }

  static incomeChart(payload: any, filters: Filters): Promise<any> {
    return window.ipcRenderer.invoke('income:chart', payload, filters);
  }

  static glChart(payload: any, filters: Filters): Promise<any> {
    return window.ipcRenderer.invoke('gl:chart', payload, filters);
  }

  static etherscanImport(address: string, creds: ApiCredentials): Promise<ImportResponse> {
    return window.ipcRenderer.invoke('etherscan:import', address, creds);
  }

  static openLink(link: string): void {
    window.ipcRenderer.invoke('link:open', link);
  }

  static serviceImport(service: string, creds: ApiCredentials, ...args: any[]): Promise<any> {
    if (service === 'nimiq') {
      return new NimiqImport(creds, ...args).import();
    } else {
      return window.ipcRenderer.invoke('service:import', service, creds, ...args);
    }
  }

  static serviceVerify(service: string, creds: ApiCredentials | TokenCredentials): Promise<boolean> {
    if (service === 'nimiq') {
      return new NimiqApi(creds).verify();
    } else {
      return window.ipcRenderer.invoke('service:verify', service, creds);
    }
  }

  static serviceRefresh(service: string, creds: ApiCredentials | TokenCredentials): Promise<TokenCredentials> {
    return window.ipcRenderer.invoke('service:refresh', service, creds);
  }

  static ipcTheme(theme: DarkMode): void {
    window.ipcRenderer.invoke('dark-mode:set', theme)
      .then(shouldUseDarkColors => {
        document.body.setAttribute('data-theme', shouldUseDarkColors ? 'dark' : 'light')
      })
      .catch(err => console.error(err))
  }

  static addChildren(payload: any): Promise<any> {
    return window.ipcRenderer.invoke('db:add:children', payload);
  }

  static oauthCallback(code: string, state: any) {
    return window.ipcRenderer.invoke('oauth:callback', code, state);
  }

  static dbUpdateNotFound(): Promise<void> {
    return window.ipcRenderer.invoke('db:update:not:found');
  }

  static priceAt(date: Date, id: string): Promise<number> {
    return window.ipcRenderer.invoke('price:at', date, id);
  }

  static processPlatform(): Promise<string> {
    return window.ipcRenderer.invoke('process:platform');
  }

  static purchase(): Promise<boolean> {
    return window.ipcRenderer.invoke('purchase');
  }

  static restore(): Promise<boolean> {
    return window.ipcRenderer.invoke('restore');
  }

  static appReady(): Promise<boolean> {
    return window.ipcRenderer.invoke('appReady');
  }

  static getReceiptB64(): Promise<string> {
    return window.ipcRenderer.invoke('receipt:b64');
  }

  static clearReceipt(): Promise<boolean> {
    return window.ipcRenderer.invoke('receipt:clear');
  }

  static runMigrations(): Promise<boolean> {
    return window.ipcRenderer.invoke('db:migrations');
  }
}
