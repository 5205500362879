import { NODE_ENV } from '../environment/env.json'
const env = {
  test: {
    DB_PATH: () => Promise.resolve('./tests'),
    COINBASE_API_URL: 'https://coinbase.nobody',
    COINBASE_PRO_API_URL: 'https://pro.coinbase.nobody',
    KUCOIN_API_URL: 'https://kucoin.nobody',
    ETHERSCAN_API_URL: 'https://etherscan.nobody',
    KRAKEN_API_URL: 'https://kraken.nobody',
    GEMINI_API_URL: 'https://gemini.nobody',
    GUMROAD_API_URL: 'https://api.gumroad.nobody',
    NIMIQ_HUB_URL: 'https://hub.nimiq-testnet.com',
    NIMIQ_API_URL: 'https://test-api.nimiq.watch',
    NIMIQ_RECIPIENT: 'NQ23 2RT4 SG9Y AF5V KL81 EMT6 T79F UXKU D12S',
    SYMBOL_TAX_URL: 'https://staxdev.localhost:8081',
    SYMBOL_TAX_APP_URL: 'https://staxdev.localhost:8082',
    COINSTATS_API_URL: 'https://openapiv1.coinstats.nobody',
    AVALANCHE_API_URL: 'https://api.snowtrace.nobody',
    FANTOM_API_URL: 'https://api.ftmscan.nobody',
    FIREBASE_FUNCTIONS_ENDPOINT: 'https://api.firebase.nobody'

  },
  development: {
    DB_PATH: () => Promise.resolve('.'),
    COINBASE_API_URL: 'https://api.coinbase.com',
    COINBASE_PRO_API_URL: 'https://api-public.sandbox.pro.coinbase.com',
    // KUCOIN_API_URL: 'https://openapi-sandbox.kucoin.com',
    KUCOIN_API_URL: 'https://api.kucoin.com',
    // ETHERSCAN_API_URL: 'https://api-ropsten.etherscan.io',
    ETHERSCAN_API_URL: 'https://api.etherscan.io',
    KRAKEN_API_URL: 'https://api.kraken.com',
    GEMINI_API_URL: 'https://api.sandbox.gemini.com',
    GUMROAD_API_URL: 'https://api.gumroad.com',
    NIMIQ_HUB_URL: 'https://hub.nimiq-testnet.com',
    NIMIQ_API_URL: 'https://test-api.nimiq.watch',
    NIMIQ_RECIPIENT: 'NQ23 2RT4 SG9Y AF5V KL81 EMT6 T79F UXKU D12S',
    SYMBOL_TAX_URL: 'https://staxdev.localhost:8081',
    SYMBOL_TAX_APP_URL: 'https://staxdev.localhost:8082',
    COINSTATS_API_URL: 'https://openapiv1.coinstats.app',
    AVALANCHE_API_URL: 'https://api.snowtrace.io',
    FANTOM_API_URL: 'https://api.ftmscan.com',
    FIREBASE_FUNCTIONS_ENDPOINT: 'http://localhost:5001/symbol-tax/us-central1'
  },
  production: {
    DB_PATH: () => {
      return new Promise((resolve, reject) => {
        import('electron').then(e => resolve(e.app.getPath('userData')))
      })
    },
    COINBASE_API_URL: 'https://api.coinbase.com',
    COINBASE_PRO_API_URL: 'https://api.pro.coinbase.com',
    KUCOIN_API_URL: 'https://api.kucoin.com',
    ETHERSCAN_API_URL: 'https://api.etherscan.io',
    KRAKEN_API_URL: 'https://api.kraken.com',
    GEMINI_API_URL: 'https://api.gemini.com',
    GUMROAD_API_URL: 'https://api.gumroad.com',
    NIMIQ_HUB_URL: 'https://hub.nimiq.com',
    NIMIQ_API_URL: 'https://api.nimiq.watch',
    // NIMIQ_RECIPIENT: 'NQ15 FHNR CJXU HVEL 59HE M6YL V0P4 AU4A 64L1',
    NIMIQ_RECIPIENT: 'NQ56 JX8Q HVXM CDPN 8BBL VPFY CXHJ 8TGA CF4H',
    SYMBOL_TAX_URL: 'https://symbol.tax',
    SYMBOL_TAX_APP_URL: 'https://app.symbol.tax',
    COINSTATS_API_URL: 'https://openapiv1.coinstats.app',
    AVALANCHE_API_URL: 'https://api.snowtrace.io',
    FANTOM_API_URL: 'https://api.ftmscan.com',
    FIREBASE_FUNCTIONS_ENDPOINT: 'us-central1'
    // NIMIQ_HUB_URL: 'https://hub.nimiq-testnet.com',
    // NIMIQ_API_URL: 'https://test-api.nimiq.watch',
    // NIMIQ_RECIPIENT: 'NQ23 2RT4 SG9Y AF5V KL81 EMT6 T79F UXKU D12S',
    // SYMBOL_TAX_URL: 'https://staxdev.localhost:8081'
  }
}

export default env[NODE_ENV]