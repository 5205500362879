











export default {
  data() {
    return {
      gifLoaded: false
    }
  },
  props: {
    title: String
  },
  mounted() {
    setTimeout(() => {
      this.gifLoaded = true;
    }, 5000);
  }
}
