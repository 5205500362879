export enum DbTable {
  TRANSACTIONS = 'transactions',
  PRICES = 'prices',
  GAINS = 'gains',
  SETTINGS = 'settings',
  REPORTS = 'reports',
  COINS = 'coins',
  INCOMES = 'incomes',
  MIGRATIONS = 'migrations'
}
