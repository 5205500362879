
































































































































import Helpers from "@/helpers/ipc-helpers";
export default {
  methods: {
    openLink(url: string) {
      Helpers.openLink(url);
    },
  },
};
