<template>
  <div id="feed">
    <div itemscope itemtype="http://schema.org/Blog">
      <h3 v-if="posts.length === 0">No Posts Yet, come back soon...</h3>
      <article v-for="post in posts" :key="post.slug" class="row" itemprop="blogPost" itemscope itemtype="http://schema.org/BlogPosting">
        <meta name="mainEntityOfPage" :content="'/blog/' + post.slug">
        <div class="col">
          <h2 itemprop="name headline">{{ post.title }} <router-link :to="'/blog/' + post.slug"><b-icon-link></b-icon-link></router-link></h2>
          <b-icon-calendar></b-icon-calendar> <time itemprop="datePublished" class="mr-2">{{ post.date }}</time>
          <b-icon-tags></b-icon-tags> <span itemprop="keywords">{{ post.tags.join(', ') }}</span>
          <p itemprop="description">{{ post.description }} <router-link :to="'/blog/' + post.slug">...Read More</router-link></p>
        </div>
      </article>
    </div>
  </div>
</template>


<script>
import manifest from './manifest.json'

export default {
  data () {
    return {
      posts: manifest.posts.filter(p => new Date(p.date) <= new Date())
    }
  }
}
</script>
