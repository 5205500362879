
















import Jumbo from "@/web/components/Jumbo.vue";
import HubApi from "@nimiq/hub-api";
import env from "@/services/env";
export default {
  data() {
    return {
      code: null,
      showCode: false
    };
  },
  components: {
    Jumbo,
  },
  computed: {
    nimiqHubUrl: () => env.NIMIQ_HUB_URL,
  },
  methods: {
    verify() {
      const hub = new HubApi(this.nimiqHubUrl);
      hub
        .signMessage({
          appName: "Symbol Tax",
          message: this.$route.params.message,
        })
        .then((result) => {
          const stringified = JSON.stringify(result);
          this.code = btoa(stringified);
          this.$nextTick(() => {
            this.$refs['openNimiq'].click();
          })
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
