// import { expose } from "threads/worker";
/* @ts-ignore */
// const Nimiq = require('@nimiq/core');
// const Nimiq: any = (window as Window).Nimiq || {};
// console.debug('Nimiq...', Nimiq);

// import store from "@/store";
import { NODE_ENV } from "@/environment/env.json"
let status: Nimiq.Client.ConsensusState;
let client: Nimiq.Client;


// let status: Nimiq.Client.ConsensusState;
// let client: Nimiq.Client;
// console.log('worker loaded....');

// const mockNimiqWorker = {
//   getTransactions(address: string, height = 0): Promise<any[]> {
//     return new Promise((resolve, reject) => {
//       resolve([]);
//     });
//   },
//   getTransaction(hash: string): Promise<any> {
//     return new Promise((resolve, reject) => {
//       resolve({ sender: 'blah' });
//     });
//   },
//   start() {
//     console.log('start mock...');
//   }
// };

const nimiqWorker = {
  getTransactions(address: string, height = 0): Promise<Nimiq.ClientTransactionDetails[]> {
    return new Promise((resolve, reject) => {
      client.waitForConsensusEstablished()
        .then(() => {
          client.getTransactionsByAddress(address, height, null, 500)
            .then((txs: Nimiq.ClientTransactionDetails[]) => resolve(txs))
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    });
  },
  getTransaction(hash: string): Promise<Nimiq.ClientTransactionDetails> {
    return new Promise((resolve, reject) => {
      client.waitForConsensusEstablished()
        .then(() => {
          client.getTransaction(hash)
            .then((tx: Nimiq.ClientTransactionDetails) => resolve(tx))
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    });
  },
  async start(Nimiq: any): Promise<Nimiq.Client> {
    return await new Promise((resolve, reject) => {
      document.dispatchEvent(new CustomEvent('nimconsensus', { detail: { consensus: 'connecting' } }))
      Nimiq.init(() => {
        // console.debug('Nimiq...', Nimiq.Signature);
        // NimiqInstance.clientType = 'light';
        const config = Nimiq.GenesisConfig.CONFIGS[NODE_ENV === 'production' ? 'main' : 'test'];
        
        try  {
          Nimiq.GenesisConfig.init(config);
        } catch (e) {
          // console.debug('already init genesis config...');
          console.error(e);
        }
        
        Nimiq.Consensus.light()
          .then(consensus => {
            // console.debug('this in Consensus init...', consensus);
            client = new Nimiq.Client(Nimiq.Client.Configuration.builder().feature(Nimiq.Client.Feature.MEMPOOL).build(), consensus);
            client.addConsensusChangedListener(val => document.dispatchEvent(new CustomEvent('nimconsensus', { detail: { consensus: val } })));
            // client.addConsensusChangedListener(console.debug);
            // console.debug('nimiq client', client);
            resolve(client);
          });
      }, (code) => {
        switch (code) {
          case Nimiq.ERR_WAIT:
            reject(code);
            break;
          case Nimiq.ERR_UNSUPPORTED:
            reject('Browser not supported');
            break;
          default:
            reject('Nimiq initialization error');
            break;
        }
      });
    });
  }
};

// export type NimiqWorker = typeof mockNimiqWorker;
// expose(mockNimiqWorker);

export type NimiqWorker = typeof nimiqWorker;
export default nimiqWorker;
