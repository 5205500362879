import { CoinstatsDbCoin } from "@/services/coinstats/CoinstatsDbCoin";
import Decimal from "decimal.js-light";

export interface Transaction {
  date: Date;
  receivedSymbol: string;
  receivedAmount: Decimal;
  sentSymbol: string;
  sentAmount: Decimal;
  price: Decimal;
  fee: Decimal;
  feeSymbol: string;
  description: string;
  source: any;
  _id: string;
  children: Transaction[];
  hash?: string; // network hash
  receivedCoinId?: string;
  sentCoinId?: string;
  feeCoinId?: string;
  receivedCoin?: CoinstatsDbCoin;
  sentCoin?: CoinstatsDbCoin;
  feeCoin?: CoinstatsDbCoin;
  receivedValue?: Decimal;
  sentValue?: Decimal;
  feeValue?: Decimal;
  order_id?: string;
}

export class TransactionFactory {
  static create(file: File): Promise<Transaction[]> {
    throw new Error('DEPRECATED');
  }

  static recurseFromChildren(children: any[]): Transaction[] {
    return children.map(child => {
      return TransactionFactory.dbInverted(child);
    });
  }

  static recurseToChildren(children: Transaction[]): any[] {
    return children.map(child => {
      return TransactionFactory.dbConverted(child);
    });
  }

  static fromDB(doc: any): Transaction {
    return TransactionFactory.dbInverted(doc);
  }

  private static dbInverted(doc: any): Transaction {
    return {
      _id: doc._id,
      date: new Date(doc.date),
      receivedSymbol: doc.receivedSymbol,
      receivedAmount: doc.receivedAmount ? new Decimal(doc.receivedAmount) : new Decimal(0),
      receivedCoinId: doc.receivedCoinId,
      receivedCoin: doc.receivedCoin,
      receivedValue: doc.receivedValue ? new Decimal(doc.receivedValue) : new Decimal(0),
      sentSymbol: doc.sentSymbol,
      sentAmount: doc.sentAmount ? new Decimal(doc.sentAmount) : new Decimal(0),
      sentCoinId: doc.sentCoinId,
      sentCoin: doc.sentCoin,
      sentValue: doc.sentValue ? new Decimal(doc.sentValue) : new Decimal(0),
      price: doc.price ? new Decimal(doc.price) : new Decimal(0),
      fee: doc.fee ? new Decimal(doc.fee) : new Decimal(0),
      feeSymbol: doc.feeSymbol,
      feeCoinId: doc.feeCoinId,
      feeCoin: doc.feeCoin,
      feeValue: doc.feeValue ? new Decimal(doc.feeValue) : new Decimal(0),
      description: doc.description,
      source: doc.source,
      children: this.recurseFromChildren(doc.children || []),
      hash: doc.hash,
      order_id: doc.order_id
    };
  }

  static toDB(tx: Transaction): any {
    return TransactionFactory.dbConverted(tx);
  }

  private static dbConverted(tx: Transaction): any {
    // console.debug('tx converted...', tx);
    return {
      _id: tx._id,
      date: tx.date,
      receivedSymbol: tx.receivedSymbol ? tx.receivedSymbol.trim() : null,
      receivedAmount: tx.receivedAmount ? tx.receivedAmount.toNumber() : 0,
      receivedCoinId: tx.receivedCoinId,
      receivedCoin: tx.receivedCoin,
      sentSymbol: tx.sentSymbol ? tx.sentSymbol.trim() : null,
      sentAmount: tx.sentAmount ? tx.sentAmount.toNumber() : 0,
      sentCoinId: tx.sentCoinId,
      sentCoin: tx.sentCoin,
      price: tx.price ? tx.price.toNumber() : 0,
      fee: tx.fee ? tx.fee.toNumber() : 0,
      feeSymbol: tx.feeSymbol ? tx.feeSymbol.toString().trim() : null,
      feeCoinId: tx.feeCoinId,
      feeCoin: tx.feeCoin,
      description: tx.description ? tx.description.trim() : null,
      source: tx.source || {},
      children: this.recurseToChildren(tx.children || []),
      hash: tx.hash,
      order_id: tx.order_id,
      receivedValue: tx.receivedValue ? tx.receivedValue.toNumber() : 0,
      sentValue: tx.sentValue ? tx.sentValue.toNumber() : 0,
      feeValue: tx.feeValue ? tx.feeValue.toNumber() : 0
    };
  }
}