




















import manifest from './blog/manifest.json'

export default {
  computed: {
    blogLinks () {
      return [
        {
          name: 'Home',
          path: '/'
        },
        {
          name: 'Feed',
          path: '/blog'
        }
        // {
        //   name: 'Tags',
        //   path: '/blog/tags',
        // },
        // {
        //   name: 'Years',
        //   path: '/blog/years'
        // }
      ];
    }
  }
}
