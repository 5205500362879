<template>
  <div id="post">
    <article v-if="post" itemscope itemtype="https://schema.org/BlogPosting">
      <div class="d-flex justify-content-between align-items-center">
        <h1 itemprop="name headline">{{ post.title }}</h1>
        <div>
          <div class="d-flex justify-content-start align-items-center">
            <b-icon-calendar class="mr-2"></b-icon-calendar>
            <time itemprop="datePublished" :datetime="post.date + 'T00:00:00+00:00'">{{ post.date }}</time>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <b-icon-tags class="mr-2"></b-icon-tags>
            <span itemprop="keywords">{{ post.tags.join(', ') }}</span>
          </div>
        </div>
      </div>
      <downloads v-if="isRelease" title="Download" class="mb-3"></downloads>
      <div v-html="post.html" itemprop="articleBody"></div>
      <b-row class="mb-3">
        <b-col v-if="previousPost">
          <router-link class="btn btn-outline-dark" :to="'/blog/' + previousPost.slug"
            ><b-icon-chevron-left></b-icon-chevron-left> {{ previousPost.title }}</router-link
          >
        </b-col>
        <b-col class="text-right" v-if="nextPost">
          <router-link class="btn btn-outline-dark" :to="'/blog/' + nextPost.slug"
            >{{ nextPost.title }} <b-icon-chevron-right></b-icon-chevron-right
          ></router-link>
        </b-col>
      </b-row>
    </article>
  </div>
</template>

<script>
import Downloads from '@/web/components/Downloads.vue';
export default {
  components: { Downloads },
  data () {
    return {
      post: null
    }
  },
  computed: {
    currentIndex () {
      return this.post ? this.$manifest.posts.indexOf(this.post) : -1;
    },
    nextPost () {
      return this.currentIndex > 0 ? this.$manifest.posts[this.currentIndex - 1] : null;
    },
    previousPost () {
      return this.currentIndex >= 0 && this.currentIndex < (this.$manifest.posts.length - 1) ? this.$manifest.posts[this.currentIndex + 1] : null; 
    },
    isRelease () {
      return this.post && this.post.tags.indexOf('Release') > -1;
    }
  },
  mounted () {
    this.setPost();
    this.setTableClass();
  },
  watch: {
    $route () {
      this.setPost();
      this.setTableClass();
    }
  },
  methods: {
    setTableClass () {
      this.$nextTick(() => {
        const tables = document.querySelectorAll('table');
        for (const table of tables) {
          table.setAttribute('class', 'table');
        }
      });
    },
    setPost () {
      this.post = this.$manifest.posts.find(p => p.slug === this.$route.params.slug);
    }
  }
}
</script>