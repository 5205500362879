import { Transaction } from "@/models/transaction";
import axios, { AxiosInstance } from "axios";

export interface ApiCredentials {
  key: string;
  secret: string;
  passphrase: string;
}

export interface OauthCredentials {
  code: string;
  client_id: string;
  client_secret: string;
}

export interface TokenCredentials {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  created_at: number;
}

export interface BaseApi {
  verify(): Promise<boolean>; // used to verify api keys
  fetchTransactions(): Promise<Transaction[]>; // gets all transactions
  oauth?(): Promise<TokenCredentials>;
  refresh?(): Promise<TokenCredentials>;
  timeout: number;
}

export abstract class BaseApiService implements BaseApi {
  static API_VERSION = 'v1';
  static API_URL: string;
  timeout = 200;
  protected _credentials: ApiCredentials;
  protected _oauthCreds: OauthCredentials
  protected _tokenCreds: TokenCredentials;
  _service: AxiosInstance;
  constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials) {
    this._credentials = credentials as ApiCredentials;
    this._oauthCreds = credentials as OauthCredentials;
    this._tokenCreds = credentials as TokenCredentials;
    this.setService();
  }

  oauth(): Promise<TokenCredentials> {
    throw new Error('Base service not allowed directly.')
  }

  verify(): Promise<boolean> {
    throw new Error('Base service not allowed directly.');
  }

  fetchTransactions(): Promise<Transaction[]> {
    throw new Error('Base service not allowed directly.');
  }

  sleep (delay: number): Promise<void> {
    return new Promise(function(resolve) {
      setTimeout(resolve, delay);
    });
  }

  private setService() {
    const staticConstructor = this.constructor as typeof BaseApiService;
    this._service = axios.create({ baseURL: staticConstructor.API_URL });
    this._service.interceptors.request.use(async (config) => {
      // console.debug(config.baseURL);
      return config;
    }, error => Promise.reject(error));

    this._service.interceptors.response.use(async (response) => {
      // console.debug(response.config.params);
      // console.debug(response);
      await this.sleep(this.timeout); // timeout
      return response;
    }, (error) => {
      // error handling??? 
      console.error(error);
      return Promise.reject(error);
    });
  }
}
