 <template>
  <div id="downloads">
    <div class="d-flex justify-content-center align-items-center">
      <b-button :href="symbolTaxAppUrl" class="mr-2 icon-button" variant="outline-dark" size="lg"><b-icon-arrow-up-right-square></b-icon-arrow-up-right-square> Open App</b-button>
      <b-dropdown variant="outline-dark" class="mr-3 icon-button" size="lg">
        <template #button-content> <b-icon-cpu></b-icon-cpu> {{ title ? title : 'Download' }} </template>
        <b-dropdown-item :href="macDownloadLink" download @click="track(macDownloadLink)"
          >macOS v{{ version }}</b-dropdown-item
        >
        <b-dropdown-item-button v-b-modal.linux-help
          >Linux v{{ version }}</b-dropdown-item-button
        >
        <b-dropdown-item :href="windowsDownloadLink" download @click="track(windowsDownloadLink)"
          >Windows v{{ version }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <b-modal id="linux-help" hide-footer title="Linux">
      <p>
        Symbol Tax uses the
        <a href="https://snapcraft.io/symbol-tax" target="_blank" @click="outboundTrack('snapcraft.io')"
          >Snap Store</a
        >
        for linux distribution and can be installed from the command line
        using the snap cli:
      </p>
      <pre class="bg-light pl-3 pt-3 rounded">
  sudo snap install symbol-tax
      </pre>
      <a href="https://snapcraft.io/symbol-tax" @click="outboundTrack('snapcraft.io')" target="_blank">
        <img
          alt="Get it from the Snap Store"
          src="https://snapcraft.io/static/images/badges/en/snap-store-black.svg"
        />
      </a>
      <hr>
      <h5>Alternative Downloads:</h5>
      <ul>
        <li>x64 .deb: <a :href="debDownloadLink" download @click="track(debDownloadLink)">symbol-tax_{{ version }}_amd64.deb</a></li>
        <li>x64 .rpm: <a :href="rpmDownloadLink" download @click="track(rpmDownloadLink)">symbol-tax-{{ version }}.x86_64.rpm</a></li>
        <li>arm64 .deb: <a :href="debDownloadLinkArm64" download @click="track(debDownloadLinkArm64)">symbol-tax_{{ version }}_arm64.deb</a></li>
        <li>arm64 .rpm: <a :href="rpmDownloadLinkArm64" download @click="track(rpmDownloadLinkArm64)">symbol-tax-{{ version }}.aarch64.rpm</a></li>
        <li>armv7l .deb: <a :href="debDownloadLinkArmv7l" download @click="track(debDownloadLinkArmv7l)">symbol-tax_{{ version }}_armv7l.deb</a></li>
        <li>armv7l .rpm: <a :href="rpmDownloadLinkArmv7l" download @click="track(rpmDownloadLinkArmv7l)">symbol-tax-{{ version }}.armv7l.rpm</a></li>
      </ul>
    </b-modal>
  </div>
</template>

<script lange="ts">
import env from '@/services/env';
import { version } from '../../../package.json'
const STAX_VERSION = version
const STORAGE_URL = 'https://storage.googleapis.com/symbol-tax.appspot.com/downloads'

export default {
  props: {
    title: String
  },
  computed: {
    version: () => STAX_VERSION,
    symbolTaxAppUrl: () => env.SYMBOL_TAX_APP_URL,
    macDownloadLink: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax-${STAX_VERSION}.dmg`),
    windowsDownloadLink: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax-${STAX_VERSION}.exe`),
    debDownloadLink: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax_${STAX_VERSION}_amd64.deb`),
    rpmDownloadLink: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax-${STAX_VERSION}.x86_64.rpm`),
    debDownloadLinkArm64: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax_${STAX_VERSION}_arm64.deb`),
    rpmDownloadLinkArm64: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax-${STAX_VERSION}.aarch64.rpm`),
    debDownloadLinkArmv7l: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax_${STAX_VERSION}_armv7l.deb`),
    rpmDownloadLinkArmv7l: () =>
      encodeURI(`${STORAGE_URL}/symbol-tax-${STAX_VERSION}.armv7l.rpm`)
  },
  methods: {
    track(url) {
      if (window.gtag) {
        window.gtag('event', 'conversion_event_default', {
          download_link: url
        });
      }
    },
    outboundTrack(location) {
      if (window.gtag) {
        window.gtag('event', 'conversion_event_outbound_click', {
          download_location: location
        });
      }
    }
  },
}
</script>