<template>
  <footer style="text-align: center" class="mb-3 footer">
    <router-link to="/">&copy; Symbol Tax 2021-2023</router-link> | Colorado, USA |
    <a href="mailto:info@symbol.tax">info@symbol.tax</a> |
    <router-link to="/docs">Docs</router-link> |
    <router-link to="/blog">Blog</router-link> |
    <router-link to="/privacy">Privacy</router-link>
  </footer>
</template>

<script>
export default {}
</script>
