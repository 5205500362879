window.ipcRenderer = {
  invoke: (channel: string, ...args) => {
    switch(channel) {
      case 'link:open':
        followLink(args);
        break;
      default:
        return Promise.reject('invoker not defined... ' + channel);
    }
  },
  receive: (channel: string, func) => {
    // do nothing;
  }
}

function followLink(args: any[]) {
  const el = document.createElement('a');
  el.href = args[0];
  el.style.display = 'none';
  el.setAttribute('target', '_blank');
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}
