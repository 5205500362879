<template>
  <div>
    <h2>Transactions <b-icon-cash-stack></b-icon-cash-stack></h2>
    <p>Transactions contain the following attributes:</p>
    <ul>
      <li>Date/Timestamp</li>
      <li>Received Symbol</li>
      <li>Received Amount</li>
      <li>Received Value (USD Value)</li>
      <li>Sent Symbol</li>
      <li>Sent Amount</li>
      <li>Sent Value (USD Value)</li>
      <li>Fee</li>
      <li>Fee Symbol</li>
      <li>Fee Value (USD Value)</li>
      <li>Description</li>
    </ul>
    <p>
      If left blank, Values are automatically determined by historical price data.  Depending on
      which attributes are specified, transactions can fall into the following types:
    </p>
    <ul>
      <li>
        <strong>Trade</strong> - a transaction that has both sent and received
        symbols and amounts set.
      </li>
      <li>
        <strong>Withdrawal</strong> - a transaction that has a sent symbol and
        amount, but no received symbol or amount set.
      </li>
      <li>
        <strong>Deposit</strong> - a transaction that has a received symbol and
        amount, but no sent symbol or amount.
      </li>
      <li>
        <strong>Fee/Other</strong> - a transaction with no sent or received
        symbols or amount set. ex. a failed ethereum transaction that consumed a
        gas fee or has no transactional value.
      </li>
    </ul>
    <p>
      Transactions can be entered manually on the transactions page using the
      <b-icon-plus></b-icon-plus> icon and entering the appropriate details in
      the form.
    </p>

    <h3>Ethereum Wallet Imports <b-icon-upload></b-icon-upload></h3>
    <p>
      Ethereum transactions can be directly imported via etherscan.io by
      clicking the <b-icon-upload></b-icon-upload> icon, selecting the Wallet
      tab, and entering your ethereum address. The import will automatically
      link together transactions with the same transaction hash. Transactions
      will also be linked to corresponding deposits/withdrawals into any
      exchange imports that have processed as well that include transaction
      details.
    </p>

    <h3>Nimiq Wallet Imports <b-icon-upload></b-icon-upload></h3>
    <p>
      Nimiq transactions can be directly imported from the Nimiq blockchain by
      clicking the <b-icon-upload></b-icon-upload> icon, selecting the Wallet
      tab, selecting Nimiq and entering your Nimiq address.  Transactions hashes will
      be appropriately linked in a similar manner to Ethereum addresses.  It takes
      some time to initially sync to the Nimiq blockchain, but once synced transaction
      imports should process quickly.
    </p>

    <h3>Exchange Imports <b-icon-upload></b-icon-upload></h3>
    <p>
      Direct import from exchanges is allowed by configuring API Keys on the
      settings page. More info about API Keys can be found on the next page.
      Symbol Tax currently supports the following exchanges, with plans to
      support more. If you do not see an exchange you would like to see, email
      support@symbol.tax
    </p>
    <ul>
      <li>Coinbase</li>
      <li>Coinbase Pro</li>
      <li>Gemini</li>
      <li>Kraken</li>
      <li>KuCoin</li>
    </ul>

    <h3>CSV File Imports <b-icon-upload></b-icon-upload></h3>
    <p>
      CSV file imports are supported by adding the appropriate headers to the
      CSV file. ex.
    </p>
    <pre class="bg-light pt-3 pl-3 rounded">
date,receivedSymbol,receivedAmount,sentSymbol,sentAmount,fee,feeSymbol,description
2021-04-02T06:05:54.505Z,ETH,1,USD,2500,1,USD,Bought 1 ETH for $2500
2021-04-02T06:22:03.925Z,USD,2600,ETH,1,1,USD,Sold 1 ETH for $2600
    </pre>
    <p>
      CSV Imports can be executed by clicking the
      <b-icon-upload></b-icon-upload> icon on the transactions page, selecting
      the file tab, and clicking Browse to select a file to import.
    </p>

    <h3>Linking Transactions <b-icon-link45deg></b-icon-link45deg></h3>
    <p>
      Linking transactions is a very useful feature of Symbol Tax especially
      when importing and tracking transactions across multiple exchanges or
      wallets that may be tied to each other. One example of this is a cross
      exchange arbitrage opportunity. If you buy BTC on one exchange and sell it
      on another, its not always clear if or when that BTC was originally
      purchased at the time of sale especially when dealing with multiple
      transaction ledgers. With symbol tax, we can link together the deposit
      from one exchange to the withdrawal of another to fill in this gap.
    </p>

    <p>
      Symbol Tax offers suggestions of linked transactions of the same symbol
      with similar amounts and timestamps. These suggestions can be viewed and
      linked using the <b-icon-link45deg></b-icon-link45deg> icon on the
      transactions page.
    </p>

    <p>
      Transactions can also be manually linked by selecting them in the list
      using either the shift key or ctrl/cmd keys and selecting multiple entries
      and clicking one of the <b-icon-link45deg></b-icon-link45deg> icons as
      shown below.
    </p>

    <img class="img-fluid mb-3" src="@/views/docs/img/linking.png" />

    <p>
      Another use case for linking transactions is if a fee is recorded as a
      separate ledger entry. The fee can be linked to a transaction to be able
      to deduct it from the cost basis of a gain.
    </p>

    <h3>
      Filtering Transactions <b-icon-filter-square></b-icon-filter-square>
    </h3>

    <p>
      Transactions can be filtered by Symbol, Date Range, and Transaction Type.
      Enter date ranges in the appropriate
      <b-icon-calendar></b-icon-calendar> inputs to filter by date ranges or the
      <b-icon-filter-square></b-icon-filter-square> icon to filter by symbol or
      type.
    </p>

    <b-row class="mb-3">
      <b-col class="text-right">
        <router-link class="btn btn-outline-dark" to="/docs/api-keys"
          >API Keys <b-icon-chevron-right></b-icon-chevron-right
        ></router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import faker from "faker";

export default {
  computed: {
    paragraphs: () => faker.lorem.paragraphs(10),
  },
};
</script>